import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import HeaderLogo from "../images/evistamplogoNew.svg"
import hambuergerMenu from '../images/hamburgerMenu.svg'
const Header = ({ siteTitle }) => {
    const [scrollTop, setScrollTop] = React.useState()
    const [scrolling, setScrolling] = React.useState()
    const [isOpen, setIsOpen] = React.useState(false)
    React.useEffect(() => {
        const onScroll = e => {
            setScrollTop(e.target.documentElement.scrollTop);
            setScrolling(e.target.documentElement.scrollTop > scrollTop);
        };
        if(window !== "undefined"){
            window.addEventListener("scroll", onScroll)
          }
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop])
    return (
        <header>
            <div>
                <div className="HeaderNavbar" style={{ background: `${scrollTop > 7 ? 'white' : 'transparent'}` }}>
                    <div className="header-main">
                        <div className="Header-Logo">
                            <div>
                                <img src={HeaderLogo} />
                                <h4><Link to="/">EVISTAMP</Link></h4>
                            </div>
                            <div className="mobile-menu" onClick={() => setIsOpen(!isOpen)}>
                                <div className="menu-icon">
                                    <img src={hambuergerMenu} />
                                </div>

                            </div>
                        </div>
                        <ul className={isOpen ? 'nav-options active' : 'nav-options'}>
                            <li><a href="/#products">Products</a></li>
                            <li><Link to="/pricing" activeClassName="actlink">Pricing</Link></li>
                            <li><Link to="/partners" activeClassName="actlink">Partners</Link></li>
                            {/*<li><Link to="/stampcheck" activeClassName="actlink">Stampcheck</Link></li>*/} 
                        </ul>
                        {/*<div className={isOpen ? 'header-buttons active' : 'header-buttons'}>
                            <Link to="https://myportal.evistamp.net"><button id="Login">Log In</button></Link>
                            <Link to="https://myportal.evistamp.net"><button id="trial">Free Trial</button></Link>
    </div>*/}
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: '',
}

export default Header
