import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'
import blockchain from "../images/blockchain.svg"
import productsLogo from "../images/productsLogo.svg"
import usefulimg from "../images/usefulimg.svg"
import goalsImg from "../images/goalsImg.svg"
import golasimgSecond from "../images/golasimgSecond.svg"
import evistampLogo from '../images/evistamplogoNew.png'
import headingMobile from "../images/headingMobile.svg"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const IndexPage = () => {
    const [message, setMessage] = React.useState('')
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('First Name is required'),
        message: Yup.string()
            .required('Message is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        topic: Yup.string()
            .required('Phone is required'),
    })

    return (
        <Layout>
            <Seo title="Home" />

            <div>

                <section className="heading">
                    <hgroup>
                        <div>
                            <p>Digital Assets Certification Solution</p>
                            <h1>Welcome to Evistamp</h1>
                        </div>
                        <div className="headingMobile">
                            <img src={headingMobile} />
                        </div>

                    </hgroup>
                </section>


                <section className="Blockchain">
                    <div className="blockchain-main">
                        <article>
                            <img src={blockchain} />
                        </article>
                        <article>
                            <h6>Blockchain Timestamping</h6>
                            <h3>Timestamping in Multi Blockchains</h3>
                            <p>Prove the existence and integrity of your digital assets by timestamping them in blockchains like VeChain, Celo, Polygon or EGLD.</p>
                            <div className="blockchain-buttons">
                                <button  className="btn-started">Get Started !</button>
                                <Link to="#contact"><button className="btn-contact">Contact sales</button></Link>
                            </div>
                        </article>
                    </div>
                </section>

                <section id='products'>
                    <div className="products-main">
                        <h4>Products</h4>
                        <div className="products-article">
                            <article>
                                <h5>Evistamp PRO</h5>
                                <p>Web-based timestamping solution to give you strong legal evidence that the contents of your work existed at a point-in-time and have not changed since that time.</p>
                            </article>
                            <article>
                                <h5>Evistamp API</h5>
                                <p>API-based solution to help you automate the timestamping of internal digital assets or to integrate it in your software products.</p>
                            </article>
                            <article>
                                <h5>Stampcheck</h5>
                                <p>Verify the timestamp and integrity of your EviStamped digital assets.</p>
                            </article>
                        </div>
                    </div>
                    <div className="products-contact">
                        <div className="products-contact-article">
                            <article>
                                <img src={productsLogo} />
                            </article>
                            <article>
                                <h6>Get in Contact </h6>
                                <h3>LET'S DISCUSS</h3>
                                <p> Send us a question, new feature requests or just inquire how we can become partners to create awesome and powerful software!</p>
                                <div className="products-btn-contact">
                                <Link to="#contact"><button className="btn-sales">Contact sales</button></Link>
                                <button className="btn-free">Start for Free</button>
                                </div>
                            </article>
                        </div>
                    </div>
                </section>

                <section className="Competitors">
                    <div className="competitors-main">
                        <p className="paragraf">Built to be</p>
                        <h3>Better than Competitors</h3>
                        <div className="competitors-main-article">
                            <article>
                                <h5>Faster</h5>
                                <p>Timestamping is done in real time, there is no wait time.</p>
                            </article>
                            <article>
                                <h5>Smarter</h5>
                                <p>The only scalable blockchain timestamping solution.</p>
                            </article>
                            <article>
                                <h5>Affordable</h5>
                                <p>Cost optimization through leveraging Next Gen Blockchains.</p>
                            </article>
                        </div>
                    </div>
                    <div className="useful">
                        <div className="useful-article">
                            <article>
                                <img src={usefulimg} />
                            </article>
                            <article>
                                <h6>Built to</h6>
                                <h3>Be Useful</h3>
                                <p>Forensic Multi hashing and Multi Blockchains based time-stamping for exceedingly strong evidence of the integrity of a document/file. Obtain a timestamp report with all the links to block-chain transactions to independently verify the integrity of the timestamped content.</p>
                                <div className="articleHalf">
                                    <ul>
                                        <li><a >| Proof of Existance</a></li>
                                        <li><a >| Integrity Evidence</a></li>
                                        {/*<li><a>| Ownership Attestation</a></li>*/}
                                    </ul>
                                    <ul>
                                        {/*<li><a>| Tamper Verification</a></li>*/}
                                        <li><a>| Forensic Certification</a></li>
                                        <li><a>| Independent Validation</a></li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </section>

                <section className="ourgoals">
                    <div className="ourgoals-main">
                        <article>
                            <hgroup>
                                <h3>Our Goals</h3>
                                <p>Be part of our history and help us achieve key milestones.</p>
                                <div className="ourgoals-button">
                                    <button>1,000 Customer</button>
                                    <button>10,000 Evistamp Pro</button>
                                    <button>100,000 Evistamp API</button>
                                </div>
                            </hgroup>
                            <div className="goals-img">
                                <img src={goalsImg} />
                            </div>

                        </article>
                        <article>
                            <div className="goals-img-second">
                                <img src={golasimgSecond} />
                            </div>
                            <div className="ourgoals-text">
                                <h6>Timestamping</h6>
                                <h3>Fast and Easy</h3>
                                <p>Try now timestamping your digital assets with our reliable and smart solution.</p>
                                <button>Start for Free</button>
                            </div>
                        </article>
                    </div>
                </section>

                <section className="dummy">
                    <div className="dummy-main">
                        <article>
                            <img src={evistampLogo} />
                        </article>
                        <hgroup>
                            <p>“We encourage you to upgrade for a paying plan and send us your feedback. We reinvest the profits into refining, enhancing, upgrading our products to better serve you,our customer.”</p>
                            <h5><span> - </span>a word from<span> - </span></h5>
                            <h3>Ricta Team</h3>
                        </hgroup>
                    </div>
                </section>
                <Formik
                    initialValues={{
                        name: '',
                        message: '',
                        email: '',
                        topic: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                        console.log('values',values)
                        fetch(' https://evistamp-send-contact-form-production.evistampservice.workers.dev', {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                                //'Access-Control-Allow-Origin': '*',
                            },
                            method: 'POST',
                            body: JSON.stringify(values)
                        })
                            .then(res => res.json())
                            .then(data => {

                                console.log('data', data)
                                if(data)
                                setMessage('Thank you for submiting your request. We will answer in shortly.')
                                else 
                                setMessage(data)

                            },
                                error => console.log('err', error)
                            )
                    }}
                >
                    {({ isValid, dirty }) => (
                        <section className="contact" id="contact">
                            <h2>Contact Us</h2>
                            <Form>
                                <div className="contactBox">
                                    <Field type="text"
                                        id="name"
                                        name="name"
                                        className="name" placeholder="Enter your Name" />
                                    <div className="errorBox">
                                        <ErrorMessage name="name" />
                                    </div>
                                </div>
                                <div className="contactBox">
                                    <Field type="email"
                                        id="email"
                                        name="email"
                                        className="email" placeholder="Enter your Email" />
                                    <div className="errorBox">
                                        <ErrorMessage name="email" />
                                    </div>
                                </div>
                                <Field name="topic" as="select" className="topic">
                                    <option value="Select Topic">Select Topic</option>
                                    <option value="General">General</option>
                                    <option value="Report a bug">Report a bug</option>
                                    <option value="Ask a question">Ask a question</option>
                                    <option value="Partner Program">Partner Program</option>
                                    <option value="Sales call back ">Sales call back </option>
                                    <option value="API">API</option>
                                </Field>
                                <div className="contactBoxMessage">
                                    <Field type="text"
                                        id="text"
                                        name="message"
                                        className="message" placeholder="Enter your Message" />
                                    <div className="errorBox">
                                        <ErrorMessage name="message" />
                                    </div>
                                </div>
                                <div className="contact-button">
                                    <button disabled={!isValid && !dirty} type="submit">Send</button>
                                </div>
                            </Form>
                            {message ? <div className="messageText">{message}</div>: null}
                        </section>
                    )}
                </Formik>
            </div>

        </Layout>
    )
}

export default IndexPage
